<template>
	<div>
		<Navbar/>
		<div :style="{'background-image': `url(${require('../assets/img/cover6.jpg')})`, 'background-size': `cover`, 'background-position': 'center'}" class="pt-12">
			<div class="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8" >
				<div class="lg:text-center mb-8">
					<p class="text-base leading-6 text-blue-600 font-semibold tracking-wide uppercase"> Gaming </p>
					<h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
						{{ $t('gaming.headline') }}
					</h3>
					<p class="mt-4 max-w-2xl text-xl leading-7 text-gray-700 lg:mx-auto">
						{{ $t('gaming.headline2') }}
					</p>
				</div>
			</div>
		</div>

		<div class="lg:text-center mt-12 mx-4">
			<h3 class="mt-2 text-xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl sm:leading-10">
				{{ $t('gaming.wait') }}
			</h3>
			<p class="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
				{{ $t('gaming.wait_desc') }}
			</p>
		</div>
		<div class="max-w-screen-xl mx-auto py-5 px-4 sm:px-6 lg:px-8 " >
			<!-- Begin Mailchimp Signup Form -->
			<div id="mc_embed_signup" class="mt-3 sm:pl-64">
				<form action="https://hydrochain.us12.list-manage.com/subscribe/post?u=9e8b8fcf06ce56bc65f9eb295&amp;id=24b344baa4" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
					<div id="mc_embed_signup_scroll">
						<div class="w-4/5">
							<div class="mc-field-group">
								<label for="mce-EMAIL text-2xl">Votre email  <span class="asterisk">*</span>
								</label>
								<input type="email" value="" name="EMAIL" class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal" id="mce-EMAIL">
							</div>
						</div>

						<div id="mce-responses" class="clear">
							<div class="response" id="mce-error-response" style="display:none"></div>
							<div class="response" id="mce-success-response" style="display:none"></div>
						</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
						<div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_9e8b8fcf06ce56bc65f9eb295_24b344baa4" tabindex="-1" value=""></div>
						<div class="clear"><input type="submit" value="M'inscrire" name="subscribe" id="mc-embedded-subscribe" class="mt-4 bg-indigo-700 hover:bg-indigo-500 text-white font-bold py-2 px-4 rounded"></div>
					</div>
				</form>
			</div>

			<!--End mc_embed_signup-->

		</div>


		<div class="bg-gray-50">
			<Footer />
		</div>
	</div>
</template>

<script>
  import Navbar from "@/components/Navbar";
  import Footer from "@/components/Footer";
  export default {
    name: 'Gaming',
    metaInfo: () => ({
      title: 'HydroSaas',
      titleTemplate: '%s | Serveurs gaming haute performance',
      meta: [
        { name: 'description', content: 'Louez un serveur gaming haute performance chez HydroSaaS et hébergez vos parties de jeux multijoueurs en toute sérénité. Minecraft, GTA V, Rust, CS GO…' },
        { name: 'og:title', content: 'Location de serveurs gaming chez HydroSaaS' },
        { name: 'twitter:title', content: 'Serveurs gaming chez HydroSaaS' },
        { name: 'og:description', content: 'Louez un serveur gaming haute performance chez HydroSaaS et hébergez vos parties de jeux multijoueurs en toute sérénité. Minecraft, GTA V, Rust, CS GO…' },
        { name: 'twitter:description', content: 'Louez un serveur gaming haute performance chez HydroSaaS et hébergez vos parties de jeux multijoueurs en toute sérénité. Minecraft, GTA V, Rust, CS GO…' },
        { name: 'twitter:image', content: 'https://hydrosaas.com/default.png' },
        { name: 'og:image', content: 'https://hydrosaas.com/default.png' },
      ],
    }),
    htmlAttrs: {
      amp: true
    },
    components: {
      Footer,
      Navbar,
    },
  }
</script>
